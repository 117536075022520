import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="https://storage.googleapis.com/media.landbot.io/170948/channels/P1CK8HAHCJPP2029AZM2TUG040UZQUJM.png" className="App-logo" alt="logo" />
        <p>
         Together as a Service
        </p>
        
      </header>
    </div>
  );
}

export default App;
